.education-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    background-color: #F7F5F3; /* Secondary color from your theme */
    line-height: 1.6;
  }
  
  .education-header {
    text-align: center;
    background-color: #B5C0AF; /* Primary color */
    color: #000;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .education-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #000; /* Tertiary color */
  }
  
  .who-can-sign-up,
  .what-i-teach,
  .extras {
    background-color: #EBE2D6; /* Tertiary color */
    margin: 20px 0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 1.5rem;
    color: #8B635C; /* Quinary color */
    margin-bottom: 10px;
  }
  
  p {
    margin: 10px 0;
  }
  
  ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 8px;
  }
  
  @media (max-width: 768px) {
    .education-header h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.3rem;
    }
  
    ul li {
      font-size: 0.95rem;
    }
  
    .education-page {
      padding: 15px;
    }
  }
  