
  /* Animations for "X" (cross) when menu is open */
  .menu-open .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-open .bar2 {
    opacity: 0;
  }
  
  .menu-open .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  /* Navigation Styles */
  nav ul {
    visibility: hidden; 
    position: fixed; /* Full screen overlay */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95); /* A bit transparent */
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 50px;  /* Adjust this based on the height of your header */
    height: calc(100% - 50px);  /* Adjusting the height so it doesn't cover the header */
    font-size: 1.5rem;  /* Bigger font size */
    padding-top: 2rem;  /* Adjust spacing from the top */
    opacity: 0;
    transition: opacity .5s ease;
    font-family: "Brandon Grotesque Medium", sans-serif; 
    z-index: 1000; /* Adding a high z-index value */

  }

  .menu-item-enter-done {
    font-size: 16px;
  }
  
  nav ul.menu-open {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
  
  @media (min-width: 768px) {
    .icon-container {
        display: none;
    }      

    nav ul {
      display: flex;
      padding: 0;
      font-size: medium;
      position: static; /* Reset positioning */
      background-color: transparent; /* Reset background */
      flex-direction: row;
      justify-content: space-around;
      align-items: initial;
      visibility: visible;  /* Ensure the nav is visible */
      opacity: 1;  /* Ensure the nav is fully opaque */
    }
}

nav ul li {
    margin: 1.5rem 0;  
    cursor: pointer;  /* Give a hint to the user that it's clickable */
    padding: 0.5rem 1rem;  /* Space around each item for better appearance & click area */
    border-radius: 5px;  /* Slight rounded corners for hover effect */
    transition: background-color 0.3s ease;  /* Smooth transition for hover effect */
    text-transform: uppercase; 
}
  
  nav ul li:hover {
    background-color: rgba(255, 255, 255, 0.1);  /* A subtle hover effect */
}

  .icon-container {
    width: 30px;
    height: 30px;
  }
  
  .icon-container:active {
    transform: scale(0.9);
  }

  nav ul li a {
    text-decoration: none;
    color: inherit; /* to ensure the color is consistent with the parent li element */
    display: block; /* this makes the whole area clickable */
}

nav ul li a.active {
    text-decoration: underline;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}






  